import React, { createContext, useContext, useEffect, useState } from 'react';

const StateContext = createContext();

const usuarioEjemplo = {
        "id": 0,
        "nombre": "",
        "apellidos": " ",
        "apodo": "",
        "fecha_nacimiento": "0000-00-00",
        "peso": 0,
        "altura": 0,
        "imagen": "",
        "posicion": "",
        "numero": 0
    }

export const ContextProvider = ({ children }) => {

    const [user, setUser] = useState(usuarioEjemplo);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            userInitialState().then((result) => {
                setUser(result);
            });
        }
    }, []);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <StateContext.Provider value={{ user, setUser }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);

function userInitialState() {

    if (localStorage.getItem('token')) {

        const userInitialState = async () => {
            if (localStorage.getItem('token')) {
                const myHeaders = new Headers();
                myHeaders.append("token", localStorage.getItem('token'));

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };

                try {
                    const response = await fetch("https://server.pabloeguilaz.es/aldinfc/api/user", requestOptions);
                    const result = await response.json();

                    console.log(result);

                    if (result.code) {
                        localStorage.removeItem('token');
                        return usuarioEjemplo;
                    }

                    console.log("Retorno de la función userInitialState: ", result);
                    return result;
                } catch (error) {
                    console.error(error);
                }
            } else {
                return usuarioEjemplo;
            }
        };

        return userInitialState();

    } else {
        return usuarioEjemplo;
    }

}