import React, { useEffect } from 'react'
import { convertirFecha } from '../functions/funcions'

import { Link } from 'react-router-dom'

export default function Comunicado({ comunicado }) {
  return (
    <Link to={"../comunicados/" + comunicado.id} class="group rounded-lg bg-box dark:bg-box_dark border border-box_border dark:border-box_border_dark text-black dark:text-white shadow dark:shadow-box_border_dark cursor-pointer hover:translate-y-[-8px] transition-all" >
      <div className='flex justify-around'>
        <img class="object-cover h-[196px] w-full rounded-t-lg" src={comunicado.image} alt="" />
      </div>
      <div className='px-5 py-5 w-full'>
        <h2 className='text-[14px] font-bold group-hover:text-primary transition-all'>{comunicado.title}</h2>
      </div>
    </Link>
  )
}

/*
<Link to={"comunicados/" + comunicado.id} class="flex flex-col items-center rounded-lg md:flex-row group " >
      <img class="object-cover w-full md:w-2/3 rounded-lg group-hover:scale-105 transition-all" src={comunicado.image} alt="" />
      <div className='py-[30px] md:p-[30px] w-full md:w-1/3'>
        <h2 className='text-3xl font-bold group-hover:text-primary transition-all' >{comunicado.title}</h2>
      </div>
    </Link>
*/