export function convertirFecha(fechaStr) {
    if (fechaStr != "") {
        const meses = [
            "enero", "febrero", "marzo", "abril", "mayo", "junio",
            "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
        ];

        // Convertir la fecha de cadena a objeto Date
        const fecha = new Date(fechaStr);

        // Obtener el día, mes y año
        const dia = fecha.getDate();
        const mes = meses[fecha.getMonth()];
        const año = fecha.getFullYear();

        // Obtener la hora y minutos
        let horas = fecha.getHours();
        let minutos = fecha.getMinutes();

        // Agregar un cero delante de los minutos si es necesario
        minutos = minutos < 10 ? "0" + minutos : minutos;

        // Formatear la fecha en el nuevo formato
        const fechaFormateada = `${dia} de ${mes} de ${año} ${horas}:${minutos}`;

        if (dia == NaN) {
            return ""
        } else {
            return fechaFormateada;
        }
        
    } else {
        return ""
    }
}

export function distributeIntoSubarrays(arr) {
  const c1 = [], c2 = [], c3 = [];

  arr.forEach((item, index) => {
    if (index % 3 === 0) {
      c1.push(item);
    } else if (index % 3 === 1) {
      c2.push(item);
    } else {
      c3.push(item);
    }
  });

  return { c1, c2, c3 };
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


export async function getInicio() {

    /*
    return [
        {
            "type": "comunicado",
            "title": "Comunicado",
            "content": "El partido de la jornada 2 de la liga de fútbol sala de la Universidad de Almería se jugará el próximo 11 de octubre a las 20:00 en el pabellón de deportes de la UAL.",
            "image": "https://ui.shadcn.com/placeholder.svg",
            "date": new Date(process.env.REACT_APP_NEXT_DATE)
        },
        {
            "type": "CountDown",
            "date": new Date(process.env.REACT_APP_NEXT_DATE)
        }
    ]*/

    try {
        const response = await fetch('https://server.pabloeguilaz.es/aldinfc/api/inicio');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        //console.error('There was a problem with fetching the data:', error);
        return null;
    }

}

