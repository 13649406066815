import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { useStateContext } from '../functions/ContextProvider'

export default function EditarComunicado() {

  const id = useParams().id

  const { user, setUser } = useStateContext();

  const [comunicado, setComunicado] = useState({ content: [{ content: '' }] })
  const [contenido, setContenido] = useState("")

  useEffect(() => {
    // Llamar a la API para obtener el comunicado
    fetch(`https://server.pabloeguilaz.es/aldinfc/api/comunicados/${id}`)
      .then(response => response.json())
      .then(data => {
        setComunicado(data)
        setContenido(getContenido())
        document.title = data.title
      })
  }, [id])

  function getContenido() {
    let contenido = ""
    comunicado.content.forEach(element => {
      contenido += element.content + "\n"
    });
    return contenido
  }

  const textareaRef = useRef(null);

  // Función para ajustar la altura del textarea
  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';  // Resetea la altura
      textarea.style.height = `${textarea.scrollHeight}px`;  // Ajusta la altura al scrollHeight
    }
  };

  // Ajusta la altura cuando el valor cambia
  useEffect(() => {
    adjustHeight();
  }, [contenido]);

  const handleChange = (event) => {
    //setContenido(event.target.value);
  };


  return (
    <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-5'>
      <div class="grid gap-2">
        <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" for="titulo">Titulo</label>
        <input type="text" class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50" name="titulo" placeholder="Titulo" required="" value={comunicado.title} />
      </div>
      <div class="grid gap-2">
        <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" for="subtitle">Subtitulo</label>
        <input type="text" class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50" name="subtitle" placeholder="Subtitulo" required="" value={comunicado.subtitle} />
      </div>
      <div class="grid gap-2">
        <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" for="contendo">Contenido</label>
        <textarea class="flex min-h-auto w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50" name="contendo" placeholder="Contenido" value={contenido} ref={textareaRef}

          onChange={handleChange}
          style={{
            overflow: 'hidden',
            resize: 'none', // Evita que el usuario pueda cambiar manualmente el tamaño
          }}
          rows={1}></textarea>
      </div>
    </div>
  )
}
