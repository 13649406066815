import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import BlurFade from '../components/ui/BlurFade';

import { motion } from "framer-motion";
import { default as PartidoPage } from '../pages/Partido';
import { default as Partido } from '../components/Partido';


import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalTrigger,
} from '../components/ui/animated-modal'

const BLUR_FADE_DELAY = 0.04;


export default function Partidos() {
    const [PartidosData, setPartidosData] = useState([]);
    let delayCounter = 0; // Contador global para el delay
    ;
    useEffect(() => {
        fetch('https://server.pabloeguilaz.es/aldinfc/api/partidos/grouped')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setPartidosData(data);
                window.document.title = "Aldin FC | Resultados";
            })
            .catch(() => {
                toast.error('Error al cargar los resultados');
            });

        window.document.title = "Aldin FC | Resultados";
    }, []);

    const [partido, setPartido] = useState(null)

    if (PartidosData.length === 0) {
        return (
            <>
                <meta name="og:title" content="Partidos | Aldin FC" />
                <meta name="og:description" content="Resultados de los partidos de Aldin FC" />
                <link rel='canonical' href='https://aldinfc.com/partidos' />'

                {/* Twitter Meta */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@aldin_f.c" />
                <meta name="twitter:title" content="Resultados de los partidos de Aldin FC" />
                <meta name="twitter:description" content="Resultados de los partidos de Aldin FC" />
                <meta name="twitter:image" content="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" />

                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px]">
                    <h1 className="text-3xl font-bold text-center sr-only">Partidos</h1>
                    <div className="flex items-center justify-center w-full py-8">
                        <BlurFade delay={BLUR_FADE_DELAY}>
                            <p className="text-lg text-center text-muted-foreground"></p>
                        </BlurFade>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <meta name="og:title" content="Partidos | Aldin FC" />
            <meta name="og:description" content="Resultados de los partidos de Aldin FC" />
            <meta name='description' content='Resultados de los partidos de Aldin FC' />
            <link rel='canonical' href='https://aldinfc.com/partidos' />

            {/* Twitter Meta */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@aldin_f.c" />
            <meta name="twitter:title" content="Resultados de los partidos de Aldin FC" />
            <meta name="twitter:description" content="Resultados de los partidos de Aldin FC" />
            <meta name="twitter:image" content="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" />

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px]">
                <h1 className="text-3xl font-bold text-center sr-only">Partidos</h1>
                <Modal>
                    <ModalBody>
                        <ModalContent>
                            <PartidoPage partido={partido} />
                        </ModalContent>
                    </ModalBody>
                    {PartidosData.map((temporadas) => (
                        <div key={temporadas.temporada} className="flex items-center justify-between w-full">
                            <div className="space-y-1 w-full">
                                <BlurFade delay={BLUR_FADE_DELAY * delayCounter++}>
                                    <h2 className="text-2xl font-semibold tracking-tight">{temporadas.temporada}</h2>
                                </BlurFade>
                                {temporadas.competiciones.map((competicion) => (
                                    <div key={competicion.competicion} className="py-2">
                                        <BlurFade delay={BLUR_FADE_DELAY * delayCounter++}>
                                            <p className="text-sm text-muted-foreground">{competicion.competicion}</p>
                                        </BlurFade>
                                        <section className="grid gap-6 grid-cols-[repeat(auto-fill,minmax(300px,1fr))] w-full py-3">
                                            {competicion.partidos.map((partido) => (
                                                <BlurFade delay={BLUR_FADE_DELAY * delayCounter++} key={partido.id}>
                                                    <Link to={`/partidos/${partido.id}`}>
                                                        <ModalTrigger className="w-full pb-0 pr-0 pl-0">
                                                            <Partido partido={partido} />
                                                        </ModalTrigger>
                                                    </Link>
                                                </BlurFade>
                                            ))}
                                        </section>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Modal>
            </div>
        </>
    );
}

