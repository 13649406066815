import React, { useState, useEffect } from 'react'

import { Link, useParams } from 'react-router-dom'

import BlurFade from '../components/ui/BlurFade';

const BLUR_FADE_DELAY = 0.04

export default function Change() {

    const id = useParams().id

    const [change, setChange] = useState(null)

    useEffect(() => {

        // Cambiar el titulo de la pagina
        window.document.title = "Aldin FC | Changelog"

        // Llamar a la API para obtener el comunicado
        fetch(`https://server.pabloeguilaz.es/aldinfc/api/changelog/${id}`)
            .then(response => response.json())
            .then(data => {
                setChange(data)
                window.document.title = "Aldin FC | Changelog"
            })

        window.document.title = "Aldin FC | Changelog"

    }, [])

    if (change === null) {
        return (
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px]'>
                <h1 className='text-3xl font-bold text-center sr-only'>Changelog</h1>
                <div className='flex items-center justify-center w-full py-8'>
                    <BlurFade delay={BLUR_FADE_DELAY}>
                        <p className='text-lg text-center text-muted-foreground'></p>
                    </BlurFade>
                </div>
            </div>
        )
    }




    return (
        <>
            <div class="slide mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-[40px] mb-[20px] z-20">
                <Link to={"../changelog"} class="inline-flex justify-center gap-x-1.5 rounded-md bg-box dark:bg-box_dark px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-box_border_dark dark:text-white hover:bg-gray-50 hover:dark:bg-black mb-[10px]">Atras</Link>
                <div class="px-4 sm:px-6 md:px-8">
                    <div class="max-w-3xl mx-auto">
                        <div>
                            <section class="relative pt-10">
                                <h1 class="text-2xl font-extrabold tracking-tight text-slate-900 dark:text-slate-200 md:text-3xl ">{change.title}</h1>
                                <div class="text-sm leading-6">
                                    <dl>
                                        <dt class="sr-only">Date</dt>
                                        <dd class="absolute top-0 inset-x-0 text-slate-700 dark:text-slate-400">
                                            <time datetime="2023-12-20T20:00:00.000Z">{change.datetime}</time>
                                        </dd>
                                    </dl>
                                </div>
                                <div class="mt-12 prose prose-slate dark:prose-dark dark:text-white">
                                    <p>{change.description}</p>
                                </div>
                            </section>
                            <section>
                                <p class="font-bold text-xl dark:text-white">Cambios</p>
                                <div class="flex flex-wrap gap-1 py-2">
                                    {
                                        change.files.map((file, index) => (
                                            <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }}>
                                                <div class="ring-gray-300 boder border-box_border cursor-pointer inline-flex items-center rounded-md border px-2.5 md:px-4 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-box dark:bg-box_dark text-black dark:text-white shadow">
                                                    <img src={"https://pabloeguilazperez.github.io/PabloEguilazPerez/icons/js.svg"} alt={file.filename} class="size-5 md:size-7 mr-1 md:p-0.5" />
                                                    <p className='text-black dark:text-white'>{file.filename}</p>
                                                </div>
                                            </div>

                                        ))
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
