import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { useStateContext } from '../functions/ContextProvider';
import { LuUser } from "react-icons/lu";

import { motion } from "framer-motion";

const navItems = [
    {
        name: 'Inicio',
        path: ''
    },
    {
        name: 'Resultados',
        path: '/partidos'
    },
    {
        name: 'Plantilla',
        path: '/jugadores'
    },
    {
        name: "Galería",
        path: '/galeria'
    },
    /*
    {
        name: "Tienda",
        path: '/store'
    }
    */
]

export default function Header({ viewSelect, setView }) {

    const [verMenuMovil, setVerMenuMovil] = useState(false)

    const { user, setUser } = useStateContext();

    const [indicator, setIndicator] = useState({ x: 0, w: 0 })

    return (
        <>
            {/*hidden lg:block*/}
            <nav className="bg-gradient-to-b from-white to-[#ffffffab] backdrop-blur-[10px] dark:bg-box_dark sticky top-0 z-50 font border-b border-box_border dark:border-box_border_dark">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 justify-between">
                        <div className="flex w-full">
                            <div className="hidden lg:flex flex-shrink-0 items-center">
                                <Link to="" className='h-full flex'>
                                    <div className="flex flex-shrink-0 items-center">
                                        <img className='w-[39px] h-[39px] bg-primary rounded-lg p-[4px]' src='https://server.pabloeguilaz.es/aldinfc/static/escudo.png' alt='AldinFC Escudo' />
                                    </div>
                                </Link>
                                <p className="pl-3 hidden lg:block"></p>
                            </div>
                            {/*
                            <div className='absolute w-full bg-green-400/30 h-[30px] left-0'>
                                <motion.div
                                    animate={{ x: indicator.x, width: indicator.w, y: 15 }}
                                    transition={{ type: "spring" }}

                                    className='absolute w-[30px] h-[30px] bg-primary/30 rounded-lg p-[4px]'
                                />
                            </div>
                            */}
                            <div className="hidden w-full lg:-my-px lg:ml-6 lg:flex lg:space-x-8">
                                {
                                    navItems.map((e, index) => {
                                        return (
                                            <Link
                                                to={e.path}
                                                key={index}
                                                id={`header-link-${e.name}-desktop`}
                                                onMouseEnter={() => {
                                                    window.document.title = "Aldin FC | Perfil"
                                                    // Obtener la posicion del elemento
                                                    const element = document.getElementById(`header-link-${e.name}-desktop`)
                                                    const rect = element.getBoundingClientRect()
                                                    setIndicator({ x: rect.x, w: rect.width })
                                                }}
                                                onClick={() => setView(e.name)} className={viewSelect == e.name ? "border-slate-500 text-gray-900 dark:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium" : "border-transparent text-gray-500 hover:text-gray-700 dark:hover:text-white hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"}
                                            >
                                                {e.name}
                                            </Link>
                                        )
                                    })
                                }
                                <div className="w-full flex justify-end items-center">
                                    <div className="flex items-center flex-col">
                                        {
                                            user.id != 0 ? (
                                                <Link
                                                    to='/perfil'
                                                    className='flex items-center border-transparent text-gray-500 hover:bg-primary rounded-lg hover:text-white px-1 pt-1 border-b-2 text-sm font-medium'
                                                >
                                                    <LuUser className='w-[20px] h-[20px]' />
                                                    <p className='pl-2'>{user.nombre} {user.apellidos}</p>
                                                </Link>
                                            ) : (<></>)
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* Mobile Menu Header */}
                            <div className="flex justify-end s-my-px mx-6 lg:hidden space-x-8 w-full">
                                {
                                    verMenuMovil ?
                                        <p onClick={() => setVerMenuMovil(!verMenuMovil)} className="cursor-pointer text-gray-900 border-transparent hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" className='w-[20px] h-[20px]' xmlns="http://www.w3.org/2000/svg"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></p>
                                        :
                                        <p onClick={() => setVerMenuMovil(!verMenuMovil)} className="cursor-pointer text-gray-900 border-transparent hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="block h-6 w-6"><path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path></svg></p>
                                }
                                <div className="flex justify-center content-center items-center w-full">
                                    <img src="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" alt='AldinFC Escudo' className='h-full py-2' />
                                </div>
                                <div className='w-[32px]'></div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Mobile Menu*/}
                <div className={verMenuMovil == true ? "lg:hidden" : "hidden"}>
                    <div className="space-y-1 pt-2 pb-3">
                        {
                            navItems.map((e, index) => {
                                return (
                                    <Link to={e.path} key={index} onClick={() => {
                                        setVerMenuMovil(!verMenuMovil)
                                        setView(e.name)
                                    }} className={viewSelect == e.name ? "bg-slate-50 dark:bg-[#121212] dark:text-white border-slate-500 text-slate-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium" : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"}>{e.name}</Link>
                                )
                            })
                        }
                        {
                            user.id != 0 ? (
                                <Link to='/perfil'
                                    onClick={() => {
                                        setVerMenuMovil(!verMenuMovil)
                                        setView('Perfil')
                                    }}

                                    className={"flex " + (viewSelect == "Perfil" ? "bg-slate-50 dark:bg-[#121212] dark:text-white border-slate-500 text-slate-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium" : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium")}>
                                    <LuUser className='w-[20px] h-[20px]' />
                                    <p className='pl-2'>{user.nombre}</p>
                                </Link>
                            ) : (<></>)
                        }
                    </div>
                </div>
            </nav>
        </>
    )
}