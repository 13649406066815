import React, { useEffect } from 'react'
import { Comunicado, CountDown, Embed } from '../components'

import { getInicio } from '../functions/funcions'

import { Partido } from '../components'

import Palmares from '../components/Palmares'

import BlurFade from '../components/ui/BlurFade'
import BentoGrid from '../components/ui/BentoGrid'
import LazyComponent from '../components/ui/LazyComponent'
import NumberTicker from '../components/NumberTicker'
import { Link } from 'react-router-dom'

const BLUR_FADE_DELAY = 0.04;

export default function Inicio() {

  const [inicio, setInicio] = React.useState(null)

  useEffect(() => {
    getInicio()
      .then((data) => {
        setInicio(data)
        window.document.title = "Aldin FC"
      })

    // Bucar cada minuto si hay un partido en directo
    const interval = setInterval(() => {
      getInicio()
        .then((data) => {
          setInicio(data)
        })
    }, 60000);
  }, [])

  function shuffle(arr) {
    var i,
      j,
      temp;
    for (i = arr.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }
    return arr;
  };

  if (inicio == null) {
    return (
      <></>
    )

  }

  return (
    <div className='my-5'>
      <meta name="description" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      {/* Twitter Meta */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@aldin_f.c" />
      <meta name="twitter:title" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      <meta name="twitter:description" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      <meta name="twitter:image" content="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" />
      {/* Open Graph Meta */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      <meta property="og:description" content="Aldin FC es un club de fútbol. Fundado en 2023." />
      <meta property="og:image" content="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" />
      <meta property="og:url" content="https://aldinfc.es/inicio" />
      <meta property="og:site_name" content="Aldin FC" />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content="Aldin FC Escudo" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:see_also" content="https://aldinfc.es" />
      <meta property="og:see_also" content="https://server.pabloeguilaz.es/aldinfc/static/escudo.png" />

      <link rel="canonical" href="https://aldinfc.es" />

      <h1 className='text-3xl font-bold text-center sr-only'>Inicio</h1>

      {/* <StaggeredLetterPullUpAnimation words={"Hola no se que poner amigo mio"} /> */}

      {/*

      <BlurFade delay={BLUR_FADE_DELAY * 2}>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>

          {
            inicio.partidos ? (
              <section className='flex flex-col md:flex-row justify-between gap-[40px]'>
                {
                  inicio.partidos.ultimo ? (
                    <div className='w-full md:w-1/2'>
                      <h2 className='text-2xl font-bold pb-3'>Último partido</h2>
                      <Link to={""}>
                        <Partido partido={inicio.partidos.ultimo} />
                      </Link>
                    </div>
                  ) : (<></>)
                }
                {
                  inicio.partidos.proximo ? (
                    <div className='w-full md:w-1/2'>
                      <h2 className='text-2xl font-bold pb-3'>Próximo partido</h2>
                      <Link to={""}>
                        <Partido partido={inicio.partidos.proximo} />
                      </Link>
                    </div>
                  ) : (<></>)
                }
              </section>
            ) : (<></>)
          }

        </div>
      </BlurFade>

      */}

      {
        inicio.partidos.en_directo ? (
          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-5'>
            <div className="z-50 flex flex-col md:flex-row justify-between w-[calc(100%-2rem)] p-4 bg-white border border-box_border rounded-lg shadow-sm lg:max-w-7xl top-6 dark:bg-gray-700 dark:border-gray-600">
              <div className="flex flex-col items-start justify-around w-full mb-3 me-4 md:items-center md:flex-row md:mb-0">
                <p className="w-full text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em]">{inicio.partidos.en_directo.local.nombre}</p>
                <p className='w-full flex justify-around text-9xl font-bold'>{inicio.partidos.en_directo.local.goles}</p>
              </div>
              <div className="flex flex-col items-end justify-around w-full mb-3 me-4 md:items-center md:flex-row-reverse md:mb-0">
                <p className="w-full text-right text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em]">{inicio.partidos.en_directo.visitante.nombre}</p>
                <p className='w-full flex justify-around text-9xl font-bold'>{inicio.partidos.en_directo.visitante.goles}</p>
              </div>
            </div>
          </div>
        ) : <></>
      }

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-5">
        <div className="preview flex min-h-[350px] w-full justify-center items-center">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">

            <BlurFade delay={BLUR_FADE_DELAY * 1} className='mx-auto w-full relative rounded-2xl overflow-hidden col-span-1 lg:col-span-2 h-full bg-primary min-h-[500px] lg:min-h-[300px]'>
              <Link to={"/comunicados/" + inicio.ultima_noticia.id} className='mx-auto w-full relative rounded-2xl overflow-hidden col-span-1 lg:col-span-2 h-full bg-primary min-h-[500px] lg:min-h-[300px]'>
                <div className="relative h-full [background-image:radial-gradient(88%_100%_at_top,rgba(255,255,255,0.5),rgba(255,255,255,0))]  sm:mx-0 sm:rounded-2xl overflow-hidden">
                  <div className="h-full px-4 py-20 sm:px-10">
                    <div className="absolute inset-0 w-full h-full scale-[1.2] transform opacity-10 [mask-image:radial-gradient(#fff,transparent,75%)]"></div>
                    <div className="max-w-xs">
                      <h2 className="text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">{inicio.ultima_noticia.title}</h2>
                      <p className="mt-4 text-left  text-base/6 text-neutral-200">{inicio.ultima_noticia.subtitle}</p>
                    </div>
                    <img
                      alt="linear demo image"
                      loading="lazy"
                      width="500"
                      height="500"
                      decoding="async"
                      src={inicio.ultima_noticia.image}
                      className='absolute -right-10 md:-right-[40%] lg:-right-[20%] -bottom-10 object-contain rounded-2xl'
                    />
                  </div>
                </div>
              </Link>
            </BlurFade>

            {
              inicio.countdown && inicio.countdown.length >= 1 ? (
                <BlurFade delay={BLUR_FADE_DELAY * 2} className="mx-auto w-full bg-primary relative rounded-2xl overflow-hidden col-span-1 min-h-[300px]" >
                  <div className="relative  h-full [background-image:radial-gradient(88%_100%_at_top,rgba(255,255,255,0.5),rgba(255,255,255,0))]  sm:mx-0 sm:rounded-2xl overflow-hidden">
                    <div className="h-full px-4 py-20 sm:px-10" >
                      <div className="absolute inset-0 w-full h-full scale-[1.2] transform opacity-10 [mask-image:radial-gradient(#fff,transparent,75%)]"></div>
                      <h2 className="max-w-80  text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">{inicio.countdown[0].title}</h2>
                      <p className="mt-4 max-w-[26rem] text-left  text-base/6 text-neutral-200">
                        <CountDown date={new Date(inicio.countdown[0].date)} className="text-white" />
                      </p>
                    </div>
                  </div>
                </BlurFade>
              ) : (<></>)
            }

            {
              !inicio.countdown && !inicio.partidos.proximo ? (
                <BlurFade delay={BLUR_FADE_DELAY * 2} className="mx-auto w-full bg-primary relative rounded-2xl overflow-hidden col-span-1 min-h-[300px]" >
                  <div className="relative  h-full [background-image:radial-gradient(88%_100%_at_top,rgba(255,255,255,0.5),rgba(255,255,255,0))]  sm:mx-0 sm:rounded-2xl overflow-hidden">
                    <div className="h-full px-4 py-20 sm:px-10" >
                      <div className="absolute inset-0 w-full h-full scale-[1.2] transform opacity-10 [mask-image:radial-gradient(#fff,transparent,75%)]"></div>
                      <h2 className="max-w-80  text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">Ultimo Partido</h2>
                      <p className="mt-4 max-w-[26rem] text-left  text-base/6 text-neutral-200">
                        {
                          inicio.partidos.ultimo ? (
                            <Partido partido={inicio.partidos.ultimo} />
                          ) : (<></>)
                        }
                      </p>
                    </div>
                  </div>
                </BlurFade>
              ) : (<></>)
            }

            {
              inicio.countdown ? (
                <BlurFade delay={BLUR_FADE_DELAY * 3} className="mx-auto w-full relative rounded-2xl overflow-hidden col-span-1 lg:col-span-3 bg-primary min-h-[500px] lg:min-h-[600px] xl:min-h-[300px]" >
                  <div className="relative h-full [background-image:radial-gradient(88%_100%_at_top,rgba(255,255,255,0.5),rgba(255,255,255,0))]  sm:mx-0 sm:rounded-2xl overflow-hidden" >
                    <div className="h-full px-4 py-20 sm:px-10" >
                      <div className="absolute inset-0 w-full h-full scale-[1.2] transform opacity-10 [mask-image:radial-gradient(#fff,transparent,75%)]"></div>
                      <h2 className="max-w-80  text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white pb-2">Partidos</h2>
                      <div className='flex flex-col xl:flex-row gap-5 justify-around'>
                        {
                          inicio.partidos.ultimo ? (
                            <Partido partido={inicio.partidos.ultimo} />
                          ) : (<></>)
                        }
                        {
                          inicio.partidos.proximo ? (
                            <Partido partido={inicio.partidos.proximo} />
                          ) : (<></>)
                        }
                      </div>
                    </div>
                  </div>
                </BlurFade>
              ) : (<></>)
            }

            <LazyComponent className="">
              {
                inicio.stats.partidos ? (
                  <BlurFade delay={BLUR_FADE_DELAY * 4} className="mx-auto w-full h-full bg-primary relative rounded-2xl overflow-hidden col-span-1 min-h-[300px]" >
                    <div className="relative h-full [background-image:radial-gradient(88%_100%_at_top,rgba(255,255,255,0.5),rgba(255,255,255,0))]  sm:mx-0 sm:rounded-2xl overflow-hidden">
                      <div className="h-full px-4 py-20 sm:px-10" >
                        <div className="absolute inset-0 w-full h-full scale-[1.2] transform opacity-10 [mask-image:radial-gradient(#fff,transparent,75%)]"></div>
                        <h2 className="max-w-80  text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">Partidos Jugados</h2>
                        <p className="mt-4 max-w-[26rem] h-full text-left text-white flex items-center justify-around text-9xl">
                          <NumberTicker value={inicio.stats.partidos} className='font-bold text-white' />
                        </p>
                      </div>
                    </div>
                  </BlurFade>
                ) : (<></>)
              }
            </LazyComponent>
            <LazyComponent className="col-span-1 lg:col-span-2">
              {
                inicio.palmares ? (
                  <BlurFade delay={BLUR_FADE_DELAY * 5} className='mx-auto w-full relative rounded-2xl overflow-hidden h-full bg-primary min-h-[500px] lg:min-h-[300px]'>
                    <div className="relative  h-full [background-image:radial-gradient(88%_100%_at_top,rgba(255,255,255,0.5),rgba(255,255,255,0))]  sm:mx-0 sm:rounded-2xl overflow-hidden">
                      <div className="h-full px-4 py-20 sm:px-10">
                        <div className="absolute inset-0 w-full h-full scale-[1.2] transform opacity-10 [mask-image:radial-gradient(#fff,transparent,75%)]"></div>
                        <div className="max-w-xs">
                          <h2 className="text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">Palmarés</h2>
                          <p className="mt-4 text-left  text-base/6 text-neutral-200 h-[24px]"></p>
                        </div>
                        <Palmares palmares={inicio.palmares} />
                      </div>
                    </div>
                  </BlurFade>
                ) : (<></>)
              }
            </LazyComponent>

          </div>
        </div>
      </div>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-10 flex flex-col gap-[100px]'>

        {
          inicio.ultimas_noticias ? (
            <section>
              <BlurFade delay={BLUR_FADE_DELAY * 4}>
                <h2 className='text-2xl font-bold pb-3'>Últimas noticias</h2>
              </BlurFade>
              <BentoGrid items={inicio.ultimas_noticias} />

            </section>
          ) : (<></>)
        }

      </div>

    </div>
  )
}


/* 


      <div className="w-full mb-5 md:h-[calc(100vh-133px)]">
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-[20px] flex flex-col gap-[100px]'>
          <BlurFade delay={BLUR_FADE_DELAY * 3}>
            <h2 className='text-2xl font-bold pb-3'>Redes Sociales</h2>
          </BlurFade>
        </div>
        <AppleCarousel items={inicio.contenido} />
      </div>


   <BlurFade delay={BLUR_FADE_DELAY * 2}>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>

          {
            inicio.partidos ? (
              <section className='flex flex-col md:flex-row justify-between gap-[40px]'>
                {
                  inicio.partidos.ultimo ? (
                    <div className='w-full md:w-1/2'>
                      <h2 className='text-2xl font-bold pb-3'>Último partido</h2>
                      <Link to={""}>
                        <Partido partido={inicio.partidos.ultimo} />
                      </Link>
                    </div>
                  ) : (<></>)
                }
                {
                  inicio.partidos.proximo ? (
                    <div className='w-full md:w-1/2'>
                      <h2 className='text-2xl font-bold pb-3'>Próximo partido</h2>
                      <Link to={""}>
                        <Partido partido={inicio.partidos.proximo} />
                      </Link>
                    </div>
                  ) : (<></>)
                }
              </section>
            ) : (<></>)
          }

        </div>
      </BlurFade>

      <BlurFade delay={BLUR_FADE_DELAY * 3}>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>
          <section>
            {
              inicio.ultima_noticia ? (
                <Link to={"comunicados/" + inicio.ultima_noticia.id} className="flex flex-col items-center rounded-lg md:flex-row group " >
                  <img className="object-cover w-full md:w-2/3 rounded-lg group-hover:scale-105 transition-all h-[400px]" src={inicio.ultima_noticia.image} alt="" />
                  <div className='py-[30px] md:p-[30px] w-full md:w-1/3'>
                    <h2 className='text-3xl font-bold group-hover:text-primary transition-all duration-200'>{inicio.ultima_noticia.title}</h2>
                  </div>
                </Link>
              ) : (<><p></p></>)
            }
          </section>
        </div>
      </BlurFade>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>

        {
          inicio.palmares ? (
            <section>
              {
                inicio.palmares.length > 0 ? (<h2 className='text-2xl font-bold pb-3'>Palmarés</h2>) : (<></>)
              }
              <Palmares palmares={inicio.palmares} />
            </section>
          ) : (<> </>)
        }

      </div>

      {

        inicio.marquee ? (

          <section className='flex flex-col gap-2 py-3'>
            <Marquee pauseOnHover >
              {
                shuffle(inicio.marquee).map((marquee, index) => {
                  return (
                    <figure className="mx-2 bg-box dark:bg-box_dark relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4">
                      <div className="flex flex-row items-center gap-2">
                        <img className="rounded-full" width="32" height="32" alt="" src="https://avatar.vercel.sh/jack" />
                        <div className="flex flex-col">
                          <figcaption className="text-sm font-medium dark:text-white">{marquee.nombre}</figcaption>
                          <p className="text-xs font-medium dark:text-white/40">{marquee.red_social}</p>
                        </div>
                      </div>
                      <blockquote className="mt-2 text-sm">{marquee.texto}</blockquote>
                    </figure>
                  )
                })
              }
            </Marquee>
            <Marquee pauseOnHover direction='right'>
              {

                // Desordenar el array de marquesinas

                shuffle(inicio.marquee).map((marquee, index) => {
                  return (
                    <figure className="mx-2 bg-box dark:bg-box_dark relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4">
                      <div className="flex flex-row items-center gap-2">
                        <img className="rounded-full" width="32" height="32" alt="" src="https://avatar.vercel.sh/jack" />
                        <div className="flex flex-col">
                          <figcaption className="text-sm font-medium dark:text-white">{marquee.nombre}</figcaption>
                          <p className="text-xs font-medium dark:text-white/40">{marquee.red_social}</p>
                        </div>
                      </div>
                      <blockquote className="mt-2 text-sm">{marquee.texto}</blockquote>
                    </figure>
                  )
                })
              }
            </Marquee>
          </section>

        ) : (<></>)

      }


      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>

        {
          inicio.contenido ? (
            <section>
              {
                inicio.contenido.length > 0 ? (<h2 className='text-2xl font-bold pb-3'></h2>) : (<></>)
              }
              <div className='grid grid-cols-1 md:grid-cols-2'>
                  {
                    inicio.contenido.map((contenido, index) => {
                      if (contenido.type == "embed") {
                        return (
                          <Embed key={index} embedCode={contenido.embedCode} />
                        )
                      } else if (contenido.type == "video\/mp4") {
                        return (
                          <video className='w-full md:h-[924px] rounded-lg p-2' autoPlay muted loop preload="auto" controls >
                            <source src={contenido.url} type="video/mp4" />
                          </video>
                        )
                      }
                    })
                  }
              </div>
            </section>
          ) : (<> </>)
        }

      </div>

*/