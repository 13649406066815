import React from 'react'

export default function PageNotFound() {
  return (
    <div class="container flex items-center justify-center px-6 py-12 mx-auto">
      <div class="w-full ">
        <div class="flex flex-col items-center max-w-lg mx-auto text-center">
          <p class="text-sm font-medium text-primary">404 error</p>
          <h1 class="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">Pagina no encontrada</h1>
          <p class="mt-4 text-gray-500">Lo sentimos, pero la página que buscas no existe. Por favor, comprueba la URL e inténtalo de nuevo.</p>
          <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
            <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-00gap-x-2 sm:w-auto ">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"></path></svg>
              <span>Volver atrás</span>
            </button>
            <a class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-primary rounded-lg shrink-0 sm:w-auto" href="/">Volver a la página de inicio</a>
          </div>
        </div>
      </div>
    </div>
  )
}
