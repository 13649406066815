import React, { useEffect, useState } from 'react'

export default function CountDownHelper({dateType, time}) {

    const [firstDigit, setFirstDigit] = useState({
        transform: `translateY(-${Math.floor(time / 10) * 40}px)`
    })
    
    const [secondDigit, setSecondDigit] = useState({
        transform: `translateY(-${(time % 10) * 40}px)`
    })

    useEffect(() => {
        setFirstDigit({
            transform: `translateY(-${Math.floor(time / 10) * 40}px)`
        })
        setSecondDigit({
            transform: `translateY(-${(time % 10) * 40}px)`
        })
    }, [time])
    
    return (
    <div className="flex w-16 flex-col place-items-center gap-y-2 dark:text-white">
        <div className="relative overflow-hidden">
            <div className="float-left block h-10 w-auto overflow-hidden" data-first-group>
                <div className="translate-y-0 transition-transform duration-[800ms]" style={firstDigit}>
                    <div className="h-10" data-num-0>
                        <span className="text-4xl tabular-nums">0</span>
                    </div>
                    <div className="h-10" data-num-1>
                        <span className="text-4xl tabular-nums">1</span>
                    </div>
                    <div className="h-10" data-num-2>
                        <span className="text-4xl tabular-nums">2</span>
                    </div>
                    <div className="h-10" data-num-3>
                        <span className="text-4xl tabular-nums">3</span>
                    </div>
                    <div className="h-10" data-num-4>
                        <span className="text-4xl tabular-nums">4</span>
                    </div>
                    <div className="h-10" data-num-5>
                        <span className="text-4xl tabular-nums">5</span>
                    </div>
                    <div className="h-10" data-num-6>
                        <span className="text-4xl tabular-nums">6</span>
                    </div>
                    <div className="h-10" data-num-7>
                        <span className="text-4xl tabular-nums">7</span>
                    </div>
                    <div className="h-10" data-num-8>
                        <span className="text-4xl tabular-nums">8</span>
                    </div>
                    <div className="h-10" data-num-9>
                        <span className="text-4xl tabular-nums">9</span>
                    </div>
                </div>
            </div>
            <div className="float-left block h-10 w-auto overflow-hidden" data-second-group>
                <div className="translate-y-0 transition-transform duration-[800ms]" style={secondDigit}>
                    <div className="h-10" data-num-0>
                        <span className="text-4xl tabular-nums">0</span>
                    </div>
                    <div className="h-10" data-num-1>
                        <span className="text-4xl tabular-nums">1</span>
                    </div>
                    <div className="h-10" data-num-2>
                        <span className="text-4xl tabular-nums">2</span>
                    </div>
                    <div className="h-10" data-num-3>
                        <span className="text-4xl tabular-nums">3</span>
                    </div>
                    <div className="h-10" data-num-4>
                        <span className="text-4xl tabular-nums">4</span>
                    </div>
                    <div className="h-10" data-num-5>
                        <span className="text-4xl tabular-nums">5</span>
                    </div>
                    <div className="h-10" data-num-6>
                        <span className="text-4xl tabular-nums">6</span>
                    </div>
                    <div className="h-10" data-num-7>
                        <span className="text-4xl tabular-nums">7</span>
                    </div>
                    <div className="h-10" data-num-8>
                        <span className="text-4xl tabular-nums">8</span>
                    </div>
                    <div className="h-10" data-num-9>
                        <span className="text-4xl tabular-nums">9</span>
                    </div>
                </div>
            </div>
        </div>
        <span className="text-center text-xs">{dateType}</span>
    </div>
    )
}
