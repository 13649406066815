import React, { useEffect, useState } from 'react'
import { createClient } from "@libsql/client";

export default function Sponsors() {

    const [sponsors, setSponsors] = useState([])

    useEffect(() => {
        // Llamar a la API para obtener el comunicado
        fetch(`https://server.pabloeguilaz.es/aldinfc/api/sponsors`)
            .then(response => response.json())
            .then(data => {
                setSponsors(data)
            })
    }, [])

    return (
        <section className='py-[100px]'>
            <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
                {/*Trusted by the world’s most innovative teams*/}
            </h2>
            <div className="flex justify-around">
                {
                    sponsors.map((e) => {
                        return (
                            <a href={e.homePage} target='_blank'>
                                <img
                                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                    src={e.image}
                                    alt={e.nombre}
                                    width={158}
                                    height={48}
                                />
                            </a>
                        )
                    })
                }
            </div>
        </section>
  )
}
