import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import confetti from 'canvas-confetti';

import { convertirFecha } from '../functions/funcions'

import { MdOutlineStadium } from "react-icons/md";
import { LuCalendar } from "react-icons/lu";

import { default as NumberTicker } from '../components/NumberTicker';

export default function Partido() {

    const [id, setID] = useState(useParams().id)

    const [partido, setPartido] = useState({
        "id": 4,
        "fecha": "2024-08-03 09:00:00",
        "competicion": {
            "id": 1,
            "nombre": ""
        },
        "visitante": {
            "id": 4,
            "nombre": "",
            "escudo": "",
            "goles": 0
        },
        "local": {
            "id": 1,
            "nombre": "",
            "escudo": "",
            "goles": 0
        },
        "stats": [

        ]
    })

    useEffect(() => {
        fetch('https://server.pabloeguilaz.es/aldinfc/api/partidos/' + id)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setPartido(data);
                window.document.title = "Aldin FC | Partido"
                //toast.success('Partidos cargados correctamente');
            })
            .catch((error) => {
                toast.error('Error al cargar los partidos');
            });

        window.document.title = "Aldin FC | Partido"
    }, []);



    function crearConfetti() {

        var duration = 2 * 1000;
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
        }

        var interval = setInterval(function () {
            var timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            var particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
            confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
        }, 250);

    }

    useEffect(() => {

        if (partido.local.id == 1) {
            if (partido.local.goles > partido.visitante.goles) {
                crearConfetti();
            }
        } else {
            if (partido.visitante.goles > partido.local.goles) {
                crearConfetti();
            }
        }

    }, [partido]);

    // Buscar cada 60 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            fetch('https://server.pabloeguilaz.es/aldinfc/api/partidos/' + id)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    setPartido(data);
                    window.document.title = "Aldin FC | Partido"
                    //toast.success('Partidos cargados correctamente');
                })
                .catch((error) => {
                    toast.error('Error al cargar los partidos');
                });

            window.document.title = "Aldin FC | Partido"
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <h4 className="text-lg md:text-2xl text-neutral-600 dark:text-neutral-100 font-bold text-center mb-8 flex justify-around">
                <span>{partido.local.nombre}</span>
                <span>{partido.visitante.nombre}</span>
            </h4>
            <div className='w-full flex justify-around text-9xl font-bold'>
                <span>{partido.local.goles != 0 ? <NumberTicker value={partido.local.goles}  /> : "0"}</span>
                <span>{partido.visitante.goles != 0 ? <NumberTicker value={partido.visitante.goles}  /> : "0"}</span>
            </div>
            <div className="py-10 flex justify-around max-w-sm mx-auto">
                <div className="flex items-center justify-center">
                    <MdOutlineStadium className='mr-1 text-neutral-700 dark:text-neutral-300 h-4 w-4' />
                    <span className="text-neutral-700 dark:text-neutral-300 text-sm">
                    {partido.competicion.nombre}
                    </span>
                </div>
                <div className="flex items-center justify-center">
                    <LuCalendar className='mr-1 text-neutral-700 dark:text-neutral-300 h-4 w-4' />
                    <span className="text-neutral-700 dark:text-neutral-300 text-sm">
                        {convertirFecha(partido.fecha)}
                    </span>
                </div>
            </div>
        </div>
    )
}
