import React from 'react'
import Marquee from 'react-fast-marquee'

const products = [
    {
        name: 'Camiseta Aldin FC',
        price: 20.00,
        image: 'https://demo.vercel.store/_next/image?url=https%3A%2F%2Fcdn.shopify.com%2Fs%2Ffiles%2F1%2F0754%2F3727%2F7491%2Ffiles%2Ft-shirt-1.png%3Fv%3D1689798965&w=1920&q=75'
    },
    {
        name: 'Chaqueta Aldin FC',
        price: 12.00,
        image: 'https://demo.vercel.store/_next/image?url=https%3A%2F%2Fcdn.shopify.com%2Fs%2Ffiles%2F1%2F0754%2F3727%2F7491%2Ffiles%2Fhoodie-1.png%3Fv%3D1690003482&w=640&q=75'
    },
    {
        name: 'Taza Aldin FC',
        price: 15.00,
        image: 'https://demo.vercel.store/_next/image?url=https%3A%2F%2Fcdn.shopify.com%2Fs%2Ffiles%2F1%2F0754%2F3727%2F7491%2Ffiles%2Fmug-1.png%3Fv%3D1690003527&w=1200&q=75'
    },
    {
        name: 'Gorro Aldin FC',
        price: 20.00,
        image: 'https://demo.vercel.store/_next/image?url=https%3A%2F%2Fcdn.shopify.com%2Fs%2Ffiles%2F1%2F0754%2F3727%2F7491%2Ffiles%2Fbaby-cap-black.png%3Fv%3D1690002570&w=640&q=75'
    },
    {
        name: 'Vaso Aldin FC',
        price: 12.00,
        image: 'https://demo.vercel.store/_next/image?url=https%3A%2F%2Fcdn.shopify.com%2Fs%2Ffiles%2F1%2F0754%2F3727%2F7491%2Ffiles%2Fcup-black.png%3Fv%3D1690003088&w=640&q=75'
    },
    {
        name: 'Mochila Aldin FC',
        price: 15.00,
        image: 'https://demo.vercel.store/_next/image?url=https%3A%2F%2Fcdn.shopify.com%2Fs%2Ffiles%2F1%2F0754%2F3727%2F7491%2Ffiles%2Fbag-1-dark.png%3Fv%3D1689796304&w=640&q=75'
    },
    {
        name: "Gorra Aldin FC",
        price: 20.00,
        image: 'https://demo.vercel.store/_next/image?url=https%3A%2F%2Fcdn.shopify.com%2Fs%2Ffiles%2F1%2F0754%2F3727%2F7491%2Ffiles%2Fhat-1.png%3Fv%3D1690002833&w=384&q=75'
    }
]

export default function Store() {


    
    return (
        <>
            <section class="mx-auto grid max-w-screen-2xl gap-4 px-4 pb-4 md:grid-cols-6 md:grid-rows-2 lg:max-h-[calc(100vh-200px)] pt-5">
                <div class="md:col-span-4 md:row-span-2">
                    <a class="relative block aspect-square h-full w-full" href="/product/acme-geometric-circles-t-shirt">
                        <div class="group flex h-full w-full items-center justify-center overflow-hidden rounded-lg border bg-white hover:border-blue-600 dark:bg-black relative border-neutral-200 dark:border-neutral-800">
                            <img alt="Acme Circles T-Shirt" decoding="async" data-nimg="fill" class="relative h-full w-full object-contain transition duration-300 ease-in-out group-hover:scale-105" src={products[0].image} />
                            <div class="absolute bottom-0 left-0 flex w-full px-4 pb-4 @container/label lg:px-20 lg:pb-[35%]">
                                <div class="flex items-center rounded-full border bg-white/70 p-1 text-xs font-semibold text-black backdrop-blur-md dark:border-neutral-800 dark:bg-black/70 dark:text-white"><h3 class="mr-4 line-clamp-2 flex-grow pl-2 leading-none tracking-tight">{products[0].name}</h3>
                                    <p class="flex-none rounded-full bg-blue-600 p-2 text-white">{products[0].price}€<span class="ml-1 inline hidden @[275px]/label:inline">EUR</span></p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="md:col-span-2 md:row-span-1">
                    <a class="relative block aspect-square h-full w-full" href="/product/acme-drawstring-bag">
                        <div class="group flex h-full w-full items-center justify-center overflow-hidden rounded-lg border bg-white hover:border-blue-600 dark:bg-black relative border-neutral-200 dark:border-neutral-800">
                            <img alt="Acme Drawstring Bag" decoding="async" data-nimg="fill" class="relative h-full w-full object-contain transition duration-300 ease-in-out group-hover:scale-105" sizes="(min-width: 768px) 33vw, 100vw" src={products[1].image} />
                            <div class="absolute bottom-0 left-0 flex w-full px-4 pb-4 @container/label">
                                <div class="flex items-center rounded-full border bg-white/70 p-1 text-xs font-semibold text-black backdrop-blur-md dark:border-neutral-800 dark:bg-black/70 dark:text-white">
                                    <h3 class="mr-4 line-clamp-2 flex-grow pl-2 leading-none tracking-tight">{products[1].name}</h3>
                                    <p class="flex-none rounded-full bg-blue-600 p-2 text-white">{products[1].price}<span class="ml-1 inline hidden @[275px]/label:inline">EUR</span></p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="md:col-span-2 md:row-span-1">
                    <a class="relative block aspect-square h-full w-full" href="/product/acme-cup">
                        <div class="group flex h-full w-full items-center justify-center overflow-hidden rounded-lg border bg-white hover:border-blue-600 dark:bg-black relative border-neutral-200 dark:border-neutral-800">
                            <img alt="Acme Cup" loading="lazy" decoding="async" data-nimg="fill" class="relative h-full w-full object-contain transition duration-300 ease-in-out group-hover:scale-105" sizes="(min-width: 768px) 33vw, 100vw" src={products[2].image} />
                            <div class="absolute bottom-0 left-0 flex w-full px-4 pb-4 @container/label">
                                <div class="flex items-center rounded-full border bg-white/70 p-1 text-xs font-semibold text-black backdrop-blur-md dark:border-neutral-800 dark:bg-black/70 dark:text-white">
                                    <h3 class="mr-4 line-clamp-2 flex-grow pl-2 leading-none tracking-tight">{products[2].name}</h3>
                                    <p class="flex-none rounded-full bg-blue-600 p-2 text-white">{products[2].price}€<span class="ml-1 inline hidden @[275px]/label:inline">EUR</span></p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </section>

            <Marquee>
                {
                    products.map((product, index) => {
                        return (
                            <div class="mx-2 group flex h-[260px] w-[475px] items-center justify-center overflow-hidden rounded-lg border bg-white hover:border-blue-600 dark:bg-black relative border-neutral-200 dark:border-neutral-800">
                                <img alt={product.name} loading="lazy" decoding="async" data-nimg="fill" class="relative h-full w-full object-contain transition duration-300 ease-in-out group-hover:scale-105" src={product.image} />
                                <div class="absolute bottom-0 left-0 flex w-full px-4 pb-4 @container/label">
                                    <div class="flex items-center rounded-full border bg-white/70 p-1 text-xs font-semibold text-black backdrop-blur-md dark:border-neutral-800 dark:bg-black/70 dark:text-white">
                                        <h3 class="mr-4 line-clamp-2 flex-grow pl-2 leading-none tracking-tight">{product.name}</h3>
                                        <p class="flex-none rounded-full bg-blue-600 p-2 text-white">{product.price}€<span class="ml-1 inline hidden @[275px]/label:inline">EUR</span></p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Marquee>

        </>
    )
}
