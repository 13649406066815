import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Card, AreaChart, List, ListItem } from '@tremor/react';

import NumberTicker from '../components/NumberTicker';

import { PlaceHolder } from '../src';
import { LuCalendar } from 'react-icons/lu';

const chartdata = [
    /*
    {
        date: 'Jan 22',
        SolarPanels: 2890,
        'Inverters': 2338,
    },
    {
        date: 'Feb 22',
        SolarPanels: 2756,
        'Inverters': 2103,
    },
    {
        date: 'Mar 22',
        SolarPanels: 3322,
        'Inverters': 2194,
    },
    {
        date: 'Apr 22',
        SolarPanels: 3470,
        'Inverters': 2108,
    },
    {
        date: 'May 22',
        SolarPanels: 3475,
        'Inverters': 1812,
    },
    {
        date: 'Jun 22',
        SolarPanels: 3129,
        'Inverters': 1726,
    },
    {
        date: 'Jul 22',
        SolarPanels: 3490,
        'Inverters': 1982,
    },
    {
        date: 'Aug 22',
        SolarPanels: 2903,
        'Inverters': 2012,
    },
    {
        date: 'Sep 22',
        SolarPanels: 2643,
        'Inverters': 2342,
    },
    {
        date: 'Oct 22',
        SolarPanels: 2837,
        'Inverters': 2473,
    },
    {
        date: 'Nov 22',
        SolarPanels: 2954,
        'Inverters': 3848,
    },
    {
        date: 'Dec 22',
        SolarPanels: 3239,
        'Inverters': 3736,
    },
    */
];

const valueFormatter = function (number) {
    return '$ ' + new Intl.NumberFormat('us').format(number).toString();
};

export default function Jugador() {

    const id = useParams().id

    const [jugadorData, setJugadorData] = useState({
        "id": 1,
        "nombre": "Alex",
        "apellidos": "Eguilaz Perez",
        "apodo": "Alex",
        "fecha_nacimiento": "2004-11-06",
        "peso": 65,
        "altura": 1.7,
        "imagen": "",
        "posicion": "Ma malo q el hambre",
        "numero": 69,
        "partidos": 3,
        "stats": [
            {
                "total": 1,
                "tipo": "gol"
            },
            {
                "total": 1,
                "tipo": "asistencia"
            },
            {
                "total": 1,
                "tipo": "amarilla"
            }
        ]
    })

    useEffect(() => {
        // Llamar a la API para obtener el comunicado
        fetch(`https://server.pabloeguilaz.es/aldinfc/api/jugadores/${id}`)
            .then(response => response.json())
            .then(data => {
                setJugadorData(data)
                document.title = 'Aldin FC | ' + data.nombre
            })
        
        window.document.title = 'Aldin FC | Jugador'
    }, [])

    return (
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px]'>
            <meta name="description" content={jugadorData.nombre + " jugador del Aldin FC"} />
            {/* OG Meta */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={jugadorData.nombre} />
            <meta property="og:description" content={jugadorData.nombre + " jugador del Aldin FC"} />
            <meta property="og:image" content={jugadorData.imagen} />
            <meta property="og:url" content={`https://aldinfc.es/jugadores/${id}`} />
            <meta property="og:site_name" content="Aldin FC" />
            <meta property="og:locale" content="es_ES" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content={jugadorData.nombre} />
            <meta property="og:image:type" content="image/png" />

            {/* Twitter Meta */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@aldin_f.c" />
            <meta name="twitter:title" content={jugadorData.nombre} />
            <meta name="twitter:description" content={jugadorData.nombre + " jugador del Aldin FC"} />
            <meta name="twitter:image" content={jugadorData.imagen} />
            <meta name="twitter:url" content={`https://aldinfc.es/jugadores/${id}`} />
            <meta name="twitter:creator" content="Aldin FC" />
            <meta name="twitter:domain" content="aldinfc.es" />

            <div class="mt-4 sm:mt-8">
                <h2 class="font-semibold leading-7 text-primary hover:text-primary/90">
                    <a href="/">Aldin FC</a>
                </h2>
                <h1 class="mt-2 max-w-2xl text-3xl font-extrabold tracking-tight text-slate-900 sm:text-4xl">{jugadorData.nombre}</h1>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-10 sm:gap-y-8">
                {
                    jugadorData.imagen == "" ? (
                        <img className='rounded-lg w-full' src={PlaceHolder} alt='Sin Foto' />
                    ) : (
                        <img src={jugadorData.imagen} alt={jugadorData.nombre} className='w-full h-auto rounded-lg' />
                    )
                }
                <div>
                    <h2 class="font-semibold leading-7 text-primary hover:text-primary/90">
                        Estadisticas
                    </h2>
                    <dl className="flex flex-col gap-3 pt-3">
                        {(jugadorData.stats ?? []).map((item) => (
                            <Card key={item.tipo}>
                                <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
                                    {sustituirNombre(item.tipo)}
                                </dt>
                                <dd className="mt-2 flex items-baseline space-x-2.5">
                                    <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                        <NumberTicker value={item.total} className='text-tremor-content dark:text-dark-tremor-content' />
                                    </span>
                                </dd>
                            </Card>
                        ))}

                    </dl>

                </div>
            </div>

            {/*
            <section className='mt-8 flex flex-col gap-5'>

                <Card>
                    <h3 className="text-tremor-default text-primary hover:text-primary/90 font-semibold">Aldin FC</h3>
                    <p className="text-tremor-metric text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">Datos personales</p>
                    <div className=''>
                        <div className='flex items-center'>
                            <LuCalendar className='size-4 text-tremor-content dark:text-dark-tremor-content' />
                            <p className='pl-2 text-tremor-content dark:text-dark-tremor-content'>{jugadorData.fecha_nacimiento}</p>
                        </div>
                    </div>
                </Card>

                <Card>
                    <h3 className="text-tremor-default text-primary hover:text-primary/90 font-semibold">Aldin FC</h3>
                    <p className="text-tremor-metric text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">Estadisticas</p>
                    <AreaChart
                        className="mt-4 h-72"
                        data={chartdata}
                        index="date"
                        yAxisWidth={65}
                        categories={['SolarPanels', 'Inverters']}
                        colors={['indigo', 'cyan']}
                        valueFormatter={valueFormatter}
                        noDataText='Sin Datos'
                    />
                </Card>
            </section>
            */}
        </div>
    )
}

function sustituirNombre(nombre) {

    switch (nombre) {
        case "gol":
            return "Goles"
        case "asistencia":
            return "Asistencias"
        case "amarilla":
            return "Tarjetas Amarillas"
        case "roja":
            return "Tarjetas Rojas"
        default:
            return nombre
    }

}
