import React from 'react'
import BlurFade from './BlurFade'
import { Link } from 'react-router-dom'

export default function BentoGrid({ items }) {
  return (
    <div class="preview flex flex-col gap-5 w-full justify-center items-center">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
        {
          items.slice(0, 3).map((item, index) => (
            <BlurFade delay={(0.04 * (index)) + (0.04 * 5)} className='h-full'>
              <Link to={"comunicados/" + item.id}>
                <div class="group h-full cursor-pointer row-span-1 rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black bg-white border justify-between flex flex-col space-y-4">
                  <div class="flex flex-1 w-full h-full min-h-[6rem] rounded-xl bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100">
                    <img src={item.image} alt={item.title} class="object-cover w-full md:h-[150px] h-[96px] rounded-xl" />
                  </div>
                  <div class="group-hover/bento:translate-x-2 transition duration-200 h-[calc(100% - 150px)]">
                    <div className='flex justify-normal items-center gap-2'>
                      <div class="font-sans font-bold text-neutral-600 dark:text-neutral-200 group-hover:text-primary mb-2 mt-2">{item.title}</div>
                    </div>
                    <div class="font-sans font-normal text-neutral-600 text-xs dark:text-neutral-300 group-hover:text-primary">{item.subtitle}</div>
                  </div>
                </div>
              </Link>
            </BlurFade>
          ))
        }
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
        {
          items.slice(3, 5).map((item, index) => (
            <BlurFade delay={(0.04 * (index)) + (0.04 * 5)} className={(index == 0 ? "md:col-span-2" : "")}>
              <Link to={"comunicados/" + item.id}>
                <div class="group h-full cursor-pointer row-span-1 rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black bg-white border justify-between flex flex-col space-y-4">
                  <div class="flex flex-1 w-full h-full min-h-[6rem] rounded-xl bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100">
                    <img src={item.image} alt={item.title} class="object-cover w-full md:h-[150px] h-[96px] rounded-xl" />
                  </div>
                  <div class="group-hover/bento:translate-x-2 transition duration-200 h-[calc(100% - 150px)]">
                    <div className='flex justify-normal items-center gap-2'>
                      <div class="font-sans font-bold text-neutral-600 dark:text-neutral-200 group-hover:text-primary mb-2 mt-2">{item.title}</div>
                    </div>
                    <div class="font-sans font-normal text-neutral-600 text-xs dark:text-neutral-300 group-hover:text-primary">{item.subtitle}</div>
                  </div>
                </div>
              </Link>
            </BlurFade>
          ))
        }
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
        {
          items.slice(5, 7).map((item, index) => (
            <BlurFade delay={(0.04 * (index)) + (0.04 * 5)} className={(index == 1 ? "md:col-span-2" : "")}>
              <Link to={"comunicados/" + item.id}>
                <div class="group h-full cursor-pointer row-span-1 rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black bg-white border justify-between flex flex-col space-y-4">
                  <div class="flex flex-1 w-full h-full min-h-[6rem] rounded-xl bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100">
                    <img src={item.image} alt={item.title} class="object-cover w-full md:h-[150px] h-[96px] rounded-xl" />
                  </div>
                  <div class="group-hover/bento:translate-x-2 transition duration-200 h-[calc(100% - 150px)]">
                    <div className='flex justify-normal items-center gap-2'>
                      <div class="font-sans font-bold text-neutral-600 dark:text-neutral-200 group-hover:text-primary mb-2 mt-2">{item.title}</div>
                    </div>
                    <div class="font-sans font-normal text-neutral-600 text-xs dark:text-neutral-300 group-hover:text-primary">{item.subtitle}</div>
                  </div>
                </div>
              </Link>
            </BlurFade>
          ))
        }
      </div>
    </div>
  )
}
