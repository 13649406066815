import React, { useState, useEffect } from 'react'
import CountDownHelper from '../otros/CountDownHelper'

import confetti from 'canvas-confetti';

export default function CountDown({ date, className }) {

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const distance = date.getTime() - now;
    if (distance <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        finished: true
      };
    } else {
      return {
        days: Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, "0"),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, "0"),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, "0"),
        seconds: Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, "0"),
        finished: false
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [date]);

  function crearConfetti() {

    var duration = 2 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
    }, 250);

  }

  useEffect(() => {
      
      if (timeLeft.finished) {
        //crearConfetti();
        window.location.reload(); 
      }

  }, [timeLeft]);


  // Comprobar si tiempo es 0

  if (timeLeft.finished) {
    return (
      <span className="text-4xl tabular-nums"></span>
    )
  }

  return (
    <div className={'flex flex-row gap-x-1 font-semibold uppercase w-full justify-center py-5 ' + className}>
      <CountDownHelper time={timeLeft.days} dateType={"dias"} />
      <span aria-hidden="true" class="mt-1 text-xl">:</span>
      <CountDownHelper time={timeLeft.hours} dateType={"horas"} />
      <span aria-hidden="true" class="mt-1 text-xl">:</span>
      <CountDownHelper time={timeLeft.minutes} dateType={"minutos"} />
      <span aria-hidden="true" class="mt-1 text-xl">:</span>
      <CountDownHelper time={timeLeft.seconds} dateType={"segundos"} />
    </div>
  )
}
