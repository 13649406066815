import confetti from 'canvas-confetti'
import React, { useState, useEffect } from 'react'

import { toast } from 'sonner'

import { distributeIntoSubarrays } from '../functions/funcions';

const BLUR_FADE_DELAY = 0.04;

export default function Galeria() {

    const [fotos, setFotos] = useState({ "c1": [], "c2": [], "c3": [] });

    useEffect(() => {
        fetch('https://server.pabloeguilaz.es/aldinfc/static/galeria/')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setFotos(distributeIntoSubarrays(data));
                //toast.success('Imagenes cargadas correctamente');
                window.document.title = "Aldin FC | Galería"
            })
            .catch((error) => {
                toast.error('Error al cargar los partidos');
            });
    
            window.document.title = "Aldin FC | Galería"

    }, []);


    return (
        <>
            <h1 className='text-3xl font-bold text-center sr-only'>Galeria</h1>

            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px] flex flex-col gap-[100px]'>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div class="flex flex-col gap-4">
                        {
                            fotos['c1'].map((fotos, index) => {
                                if (fotos.extension != "mp4") {
                                    return (
                                        <div>
                                            <img class="h-auto max-w-full rounded-lg" src={"https://server.pabloeguilaz.es/aldinfc/static/galeria/" + fotos.nombre + "." + fotos.extension} alt="" />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <video className='h-auto max-w-full rounded-lg' autoPlay muted loop preload="auto" >
                                            <source src={"https://server.pabloeguilaz.es/aldinfc/static/galeria/" + fotos.nombre + "." + fotos.extension} type="video/mp4" />
                                        </video>
                                    )
                                }
                            })
                        }
                    </div>
                    <div class="flex flex-col gap-4">
                        {
                            fotos['c2'].map((fotos, index) => {
                                if (fotos.extension != "mp4") {
                                    return (
                                        <div>
                                            <img class="h-auto max-w-full rounded-lg" src={"https://server.pabloeguilaz.es/aldinfc/static/galeria/" + fotos.nombre + "." + fotos.extension} alt="" />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <video className='w-full h-auto md:h-[600px] rounded-lg' autoPlay muted loop preload="auto" >
                                            <source src={"https://server.pabloeguilaz.es/aldinfc/static/galeria/" + fotos.nombre + "." + fotos.extension} type="video/mp4" />
                                        </video>
                                    )
                                }
                            })
                        }
                    </div>
                    <div class="flex flex-col gap-4">
                        {
                            fotos['c3'].map((fotos, index) => {
                                if (fotos.extension != "mp4") {
                                    return (
                                        <div>
                                            <img class="h-auto max-w-full rounded-lg" src={"https://server.pabloeguilaz.es/aldinfc/static/galeria/" + fotos.nombre + "." + fotos.extension} alt="" />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <video className='w-full h-auto md:h-[600px] rounded-lg' autoPlay muted loop preload="auto" >
                                            <source src={"https://server.pabloeguilaz.es/aldinfc/static/galeria/" + fotos.nombre + "." + fotos.extension} type="video/mp4" />
                                        </video>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>

        </>
    )
}


