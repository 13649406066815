import React, { useState, useEffect } from 'react'
import BlurFade from '../components/ui/BlurFade';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const BLUR_FADE_DELAY = 0.04;

export default function Changelog() {

    const [changes, setChanges] = useState([])

    useEffect(() => {

        // Cambiar el titulo de la pagina
        window.document.title = "Aldin FC | Changelog"

        // Llamar a la API para obtener el comunicado
        fetch(`https://server.pabloeguilaz.es/aldinfc/api/changelog`)
            .then(response => response.json())
            .then(data => {
                setChanges(data)
                window.document.title = "Aldin FC | Changelog"
            })

        window.document.title = "Aldin FC | Changelog"

    }, [])

    if (changes.length === 0) {
        return (
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px]'>
                <h1 className='text-3xl font-bold text-center sr-only'>Changelog</h1>
                <div className='flex items-center justify-center w-full py-8'>
                    <BlurFade delay={BLUR_FADE_DELAY}>
                        <p className='text-lg text-center text-muted-foreground'></p>
                    </BlurFade>
                </div>
            </div>
        )
    }

    return (
        <>
            <div class="slide mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-[40px] mb-[20px] z-20">
                {
                    changes.map((change, index) => (
                        <BlurFade delay={BLUR_FADE_DELAY * index} key={index}>
                            <Link class="sm:flex" to={"../changelog/" + change.id}>
                                {/*  Left column */}
                                <div class="relative z-10 sm:ps-0 sm:pb-20 sm:w-1/4 sm:text-end">
                                    <div class="sm:sticky sm:top-[5rem] sm:end-0">
                                        {
                                            change.version != null && (
                                                <div class="ps-7 sm:pe-[1.6125rem]">
                                                    <p class="inline-block py-1 px-2 rounded-lg text-xs font-medium bg-gray-800 text-white dark:bg-gray-700">{change.version}</p>
                                                </div>
                                            )
                                        }
                                        <div class="flex items-center gap-x-3 py-2 px-3.5 border border-transparent rounded-full mt-0.5 -ms-7 sm:ms-0 sm:-me-7">
                                            <div class="grow order-2 sm:order-1">
                                                <span class="text-sm text-gray-500">{change.date}</span>
                                            </div>
                                            <div class="flex-shrink-0 order-1 sm:order-2 h-7 w-7 flex justify-center items-center bg-white border border-slate-200 rounded-full shadow-sm dark:bg-slate-800 dark:border-slate-700">
                                                <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                    <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                                                    <line x1="16" x2="16" y1="2" y2="6"></line>
                                                    <line x1="8" x2="8" y1="2" y2="6"></line>
                                                    <line x1="3" x2="21" y1="10" y2="10"></line>
                                                    <path d="M8 14h.01"></path>
                                                    <path d="M12 14h.01"></path>
                                                    <path d="M16 14h.01"></path>
                                                    <path d="M8 18h.01"></path>
                                                    <path d="M12 18h.01"></path>
                                                    <path d="M16 18h.01"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="relative sm:w-3/4 pb-12 sm:pb-20 ps-8 md:ps-12">
                                    <div class="absolute -top-20 sm:top-0 bottom-0 start-0 sm:end-0 w-px -me-px sm:me-0 sm:-ms-px bg-slate-200 dark:bg-slate-700"></div>

                                    <div>
                                        <h2 id="v2_0_3" class="scroll-mt-10">
                                            <a class="group inline-flex items-center gap-x-2 font-semibold text-gray-800 dark:text-white" routerLink="./{{v.id}}">{change.title}</a>
                                        </h2>

                                        <p class="mt-1.5 sm:mt-3 text-sm text-gray-600 dark:text-gray-400"><ReactMarkdown>{change.description}</ReactMarkdown></p>

                                        <div class="mt-5 sm:mt-10">

                                            {
                                                change.files.length > 0 && (
                                                    <div class="inline-flex items-center gap-x-2 bg-white p-1 pe-3.5 text-sm text-gray-600 rounded-full dark:bg-slate-800 dark:text-gray-400">
                                                        <span class="inline-flex justify-center items-center w-7 h-7 rounded-full bg-[#f3f4f6] shadow-md text-teal-600 dark:bg-slate-900">
                                                            <svg class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                                            </svg>
                                                        </span>
                                                        Fixes and Improvements
                                                    </div>
                                                )
                                            }

                                            <ul class="sm:list-disc text-gray-900 sm:ms-5 mt-3 space-y-1.5 dark:text-gray-200">
                                                {
                                                    change.files.map((file) => (
                                                        <li class="ps-1 sm:ps-1 text-sm text-gray-600 dark:text-gray-400">
                                                            <span class="inline-flex items-center gap-x-2 text-gray-800 font-semibold dark:text-gray-200 pl-[5px] pr-[3px]">{file.type}:</span>
                                                            <span>{file.type}</span>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </Link>
                        </BlurFade>
                    ))
                }
            </div>
        </>
    )
}
