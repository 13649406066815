import { Card } from '@tremor/react';
import React from 'react'
import { GiTrophyCup } from "react-icons/gi";
import NumberTicker from './NumberTicker';

export default function Palmares({palmares}) {
    return (
        <div className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(250px,1fr))] pt-2'>
            {
                palmares.map((trofeo, index) => {
                    return (
                        <Trofeo key={index} trofeo={<img src={trofeo.imagen} className='h-[100px] ' alt='' />} nombre={trofeo.nombre} total={trofeo.total} />
                    )
                })
            }
        </div>
    )
}

function Trofeo({trofeo, nombre, total}) {
    return (
        <Card>
            <div className='flex justify-between px-3 items-center '>
                <div>
                    <p className='font-bold text-7xl text-primary'> <NumberTicker value={total} className='font-bold text-7xl text-primary' /></p>
                    <p className='text-sm text-gray-400'>{nombre}</p>
                </div>
                {trofeo}
            </div>
        </Card>
    )
}