import React, { useEffect, useState } from 'react'

import Jugador from '../components/Jugador'
import { Link } from 'react-router-dom';
import BlurFade from '../components/ui/BlurFade';

const BLUR_FADE_DELAY = 0.04;

export default function Jugadores() {

    const [jugadoresData, setJugadoresData] = useState([])

    useEffect(() => {

        // Cambiar el titulo de la pagina
        window.document.title = "Aldin FC | Plantilla"

        // Llamar a la API para obtener el comunicado
        fetch(`https://server.pabloeguilaz.es/aldinfc/api/jugadores`)
            .then(response => response.json())
            .then(data => {
                setJugadoresData(data)
                window.document.title = "Aldin FC | Plantilla"
            })

        window.document.title = "Aldin FC | Plantilla"

    }, [])

    if (jugadoresData.length === 0) {
        return (
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px]'>
                <h1 className='text-3xl font-bold text-center sr-only'>Jugadores</h1>
                <div className='flex items-center justify-center w-full py-8'>
                    <BlurFade delay={BLUR_FADE_DELAY}>
                        <p className='text-lg text-center text-muted-foreground'></p>
                    </BlurFade>
                </div>
            </div>
        )
    }

    return (
        <>
            {/* Twitter Meta */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@aldin_f.c" />
            <meta name="twitter:title" content="Jugadores del Aldin FC" />
            <meta name="twitter:description" content="Jugadores del Aldin FC" />

            {/* Open Graph Meta */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Jugadores del Aldin FC" />
            <meta property="og:description" content="Jugadores del Aldin FC" />
            <meta property="og:url" content="https://aldinfc.es/jugadores" />
            <meta property="og:site_name" content="Aldin FC" />
            <meta property="og:locale" content="es_ES" />

            <link rel="canonical" href="https://aldinfc.es/jugadores" />

            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-[20px]'>
                <meta name="description" content="Jugadores del Aldin FC" />
                <h1 className='pb-5 font-bold text-2xl sr-only'>Jugadores</h1>

                <section className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(300px,1fr))]'>
                    {
                        jugadoresData.map((jugador, index) => {
                            return (
                                <BlurFade delay={BLUR_FADE_DELAY * index} key={jugador.id}>
                                    <Link to={`/jugadores/${jugador.id}`} key={index}>
                                        <Jugador jugador={jugador} />
                                    </Link>
                                </BlurFade>
                            )
                        })
                    }
                </section>
            </div>
        </>
    )
}
