import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom';

import { useStateContext } from '../functions/ContextProvider';

const navItems = [
    {
        name: 'Inicio',
        path: ''
    },
    {
        name: 'Resultados',
        path: '/partidos'
    },
    {
        name: 'Plantilla',
        path: '/jugadores'
    },
    {
        name: "Galería",
        path: '/galeria'
    }
    /*
    {
        name: "Tienda",
        path: '/store'
    }
    */
]

export default function MobileHeader({ viewSelect, setView }) {

    const [verMenuMovil, setVerMenuMovil] = useState(false)

    const { user, setUser } = useStateContext();

    return (
        <nav className='hidden bg-box backdrop-blur-[10px] dark:bg-box_dark sticky top-[-64px] z-50 font border-b border-box_border dark:border-box_border_dark'>
            <div className='w-full'>
                <div className='flex justify-around w-full items-center h-16 px-4'>
                    <img className='w-[39px] h-[39px] bg-primary rounded-lg p-[4px]' src='https://server.pabloeguilaz.es/aldinfc/static/escudo.png' alt='AldinFC Escudo' />
                </div>
            </div>
            <div className='relative flex gap-4 py-2 px-5 overflow-x-scroll'>
                {
                    navItems.map((e, index) => {
                        return (
                            <Link to={""} className=''>
                                <span>{e.name}</span>
                            </Link>
                        )
                    })
                }
            </div>
        </nav>
    )
}
