import React, { useState, useEffect, Children } from 'react';

export default function BlurFade({
    children,
    className = '',
    variant = 'div',
    duration = 0.4,
    delay = 0,
    yOffset = 6,
    inView = false,
    inViewMargin = "-50px",
    blur = "6px",
}) {
    const [visible, setVisible] = useState(false);
    const Tag = variant; // Tipo de etiqueta (por ejemplo, 'span', 'p', 'div', etc.)

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(true);
        }, delay * 1000); // Convertir delay a milisegundos

        return () => clearTimeout(timer);
    }, [delay]);

    const getCharacterStyles = () => ({
        transition: `opacity ${duration}s ease-out, transform ${duration}s ease-out, filter ${duration}s ease-out`,
        transitionDelay: `${delay}s`,
        transform: visible ? 'translateY(0)' : `translateY(${yOffset}px)`,
        opacity: visible ? 1 : 0,
        filter: visible ? 'blur(0px)' : `blur(${blur})`,
    });

    return (
        <Tag className={className} style={getCharacterStyles()}>
            {children}
        </Tag>
    );
}