import React, { useState, useEffect, useRef } from 'react';

const LazyComponent = ({ children, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Delays the appearance of the component
          setTimeout(() => setIsVisible(true), 500);
          setIsVisible(true);
          observer.disconnect(); // Deja de observar una vez cargado
        }
      },
      { threshold: 0.1 } // Se activa cuando el 10% del componente es visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return <div ref={ref} className={className}>{isVisible ? children : null}</div>;
};

export default LazyComponent;
